import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

export default function MovingCard() {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <div className="flex space-x-3">
      {/* left container */}
      <motion.div
        className={`child flex-grow transition-all duration-300 transform border-2 rounded-3xl p-4 justify-center items-center bg-[${
          hovered ? "#11141f" : "#161a27"
        }] text-[#ABABAB]`}
        whileHover={{ scale: 1 }}
        layout="position"
      >
        <div>
          <img
            src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5c2_Vector%20(3).svg"
            alt="image1"
          />
        </div>
        <div className="justify-between">
          {/* <div className="lg:text-3xl text-sm w-[90%]"> */}
          <div className={`${hovered ? "text-sm" : "text-3xl"} w-[90%]`}>
            <h3>Launch Subscriptions with ScaleSecure</h3>
          </div>
          <div className="w-[90%]">
            Every big brand starts somewhere. We’ve taken brands from 0 to over
            100k subscribers. We’d love to help you do the same.
          </div>
          <motion.button
            className="p-2 rounded-full tracking-widest bg-[#11141f] hover:text-white dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 space-x-2 m-5 hover:flex-row-reverse hover:duration-300"
            whileHover={{ scale: 1 }}
          >
            <div className="rounded-full bg-[#9647ff] transform transition-transform duration-300">
              <ArrowForwardIcon />
            </div>
            <div>Why Launch with ScaleSecure</div>
          </motion.button>
        </div>
      </motion.div>

      {/* right-container */}
      <motion.div
        className={`child flex-grow transition-all duration-1000 transform rounded-3xl p-4 w-1/2 hover:w-full bg-[${
          hovered ? "#161a27" : "#11141f"
        }] text-[#ABABAB] justify-center items-center`}
        whileHover={{ scale: 1 }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        layout="position"
      >
        <div className="justify-between">
          {/* <div className="lg:text-3xl text-sm"> */}
          <div className={`${hovered ? "text-3xl" : "text-sm"}`}>
            <h3>Launch Subscriptions with ScaleSecure</h3>
          </div>

          <div>
            Every big brand starts somewhere. We’ve taken brands from 0 to over
            100k subscribers. We’d love to help you do the same.
          </div>
        </div>

        <div>
          <img
            src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5c2_Vector%20(3).svg"
            alt="image1"
          />
        </div>

        <motion.button
          className="p-2 rounded-full tracking-widest bg-[#11141f] hover:text-white dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 space-x-2 m-3 mt-5 hover:flex-row-reverse hover:duration-300"
          whileHover={{ scale: 1 }}
        >
          <div className="rounded-full bg-[#9647ff] transform transition-transform duration-300">
            <ArrowForwardIcon />
          </div>
          <div>Why Launch with ScaleSecure</div>
        </motion.button>
      </motion.div>
    </div>
  );
}
