import React, { useState } from "react";
import { Label } from "../utils/Register/Label";
import { Input } from "../utils/Register/Input";
import cn from "../utils/cn";
import Logo from "../assets/images/Scale-secure-logo-black.png";

import ContinuousScroll from "../components/ContinuousScroll";
import StarIcon from "@mui/icons-material/Star";
import { FcGoogle } from "react-icons/fc";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { TfiMicrosoft } from "react-icons/tfi";

export default function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (value) => {
    const isLengthValid = value.length >= 8;
    const isUppercaseValid = /[A-Z]/.test(value);
    const isNumberValid = /[0-9]/.test(value);
    const isSpecialCharValid = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
      value
    );
    setPasswordValid({
      length: isLengthValid,
      uppercase: isUppercaseValid,
      number: isNumberValid,
      specialChar: isSpecialCharValid,
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
  };

  const isPasswordValid = () => {
    return (
      passwordValid.length &&
      passwordValid.uppercase &&
      passwordValid.number &&
      passwordValid.specialChar
    );
  };

  const isFormFilled = () => {
    return (
      firstName &&
      lastName &&
      email &&
      isPasswordValid() &&
      password === confirmPassword
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
  };

  return (
    <div className="flex flex-col md:flex-row lg:flex-row h-screen bg-[#11141f] font-body">
      {/* left-container */}
      <div className="relative w-full lg:w-1/2 h-auto bg-[#161a27] text-white p-4 md:p-12 lg:p-12 flex flex-col justify-between">
        {/* Logo */}
        <div className="mb-4 md:mb-8 lg:mb-10 filter invert">
          <img src={Logo} alt="Logo" className="w-24 md:w-72" />
        </div>

        {/* content */}
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row mb-2">
            <div className="flex flex-row m-1">
              {[...Array(5)].map((_, index) => (
                <StarIcon key={index} />
              ))}
            </div>
            <div className="m-1 mr-2 ml-2 text-lg">RATED 5 STAR ON THE</div>
            <img
              src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5ad_Shopify%20App%20Store%201.svg"
              alt="appstore"
              className="m-1 w-24 md:w-40"
            />
          </div>

          <div className="mt-2 mb-8 text-xl md:text-3xl leading-8">
            Book a demo with our <br /> subscription experts
          </div>

          <div className="mb-5 flex flex-col">
            <div className="w-layout-hflex align-center flex m-3">
              <div className="text-yellow-400 w-8 h-8 text-center items-center p-1 bg-yellow-400/25 tracking-tight rounded-2xl">
                1
              </div>
              <div className="pl-2 ">
                Get a curated tour of the ScaleSecure platform
              </div>
            </div>
            <div className="w-layout-hflex align-center flex m-3">
              <div className="text-yellow-400 w-8 h-8 text-center items-center p-1 bg-yellow-400/25 tracking-tight rounded-2xl">
                2
              </div>
              <div className="pl-2">
                Get a curated tour of the ScaleSecure platform
              </div>
            </div>
            <div className="w-layout-hflex align-center flex m-3">
              <div className="text-yellow-400 w-8 h-8 text-center items-center p-1 bg-yellow-400/25 tracking-tight rounded-2xl">
                3
              </div>
              <div className="pl-2">
                Get a curated tour of the ScaleSecure platform
              </div>
            </div>
            <div className="w-layout-hflex align-center flex m-3">
              <div className="text-yellow-400 w-8 h-8 text-center items-center p-1 bg-yellow-400/25 tracking-tight rounded-2xl">
                4
              </div>
              <div className="pl-2">
                Get a curated tour of the ScaleSecure platform
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-3">You're in a great Company</div>
          <div>
            <ContinuousScroll />
          </div>
        </div>
      </div>

      {/* right-container */}
      <div className="md:w-1/2 bg-[#11141f] font-body flex items-center justify-center h-screen">
        <div className="max-w-md w-full mx-auto md:mx-0 rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-[#11141f] dark:text-gray-800">
          <h2 className="font-body text-2xl text-white dark:text-neutral-200 mt-3">
            Let's Get Started
          </h2>
          <p className="text-white text-sm max-w-sm mt-2 dark:text-neutral-300">
            Register for a Demo to ascertain if you can because we don't have a
            login flow yet
          </p>

          <form onSubmit={handleSubmit} className=" mt-4 w-full">
            <LabelInputContainer className="mb-4 mt-4">
              <Label className="text-white" htmlFor="FirstName">First Name</Label>
              <Input
                id="firstname"
                placeholder="First Name"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </LabelInputContainer>

            <LabelInputContainer className="mb-4 mt-4">
              <Label className="text-white" htmlFor="LastName">Last Name</Label>
              <Input
                id="lastname"
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </LabelInputContainer>

            <LabelInputContainer className="mb-4 mt-4">
              <Label className="text-white" htmlFor="email">Work Email Address</Label>
              <Input
                id="email"
                placeholder="email@company.com"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </LabelInputContainer>

            <LabelInputContainer className="mb-4 relative">
              <Label className="text-white" htmlFor="password">Password</Label>
              <Input
                id="password"
                placeholder="••••••••"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-6 text-gray-600 dark:text-gray-400 flex items-center focus:outline-none"
              >
                {passwordVisible ? (
                  <AiFillEyeInvisible size={24} />
                ) : (
                  <AiFillEye size={24} />
                )}
              </button>
            </LabelInputContainer>

            {password && (
              <ul className="mb-4">
                <li
                  className={`text-[12px] flex items-center ${
                    passwordValid.length ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {passwordValid.length ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must be at least 8 characters long.
                </li>
                <li
                  className={`text-[12px] flex items-center ${
                    passwordValid.uppercase ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {passwordValid.uppercase ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one uppercase letter.
                </li>
                <li
                  className={`text-[12px] flex items-center ${
                    passwordValid.number ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {passwordValid.uppercase ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one number.
                </li>
                <li
                  className={`text-[12px] flex items-center ${
                    passwordValid.specialChar
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {passwordValid.uppercase ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one special character.
                </li>
              </ul>
            )}

            <LabelInputContainer className="mb-4 relative">
              <Label className="text-white" htmlFor="confirmPassword">Confirm Password</Label>
              <Input
                id="confirmPassword"
                placeholder="••••••••"
                type={confirmPasswordVisible ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute right-3 top-6 text-gray-600 dark:text-gray-400 flex items-center focus:outline-none"
              >
                {confirmPasswordVisible ? (
                  <AiFillEyeInvisible size={24} />
                ) : (
                  <AiFillEye size={24} />
                )}
              </button>
            </LabelInputContainer>

            {confirmPassword && (
              <ul className="mb-4">
                <li
                  className={`text-sm ${
                    confirmPassword === password
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {confirmPassword === password
                    ? "Passwords match"
                    : "Passwords do not match"}
                </li>
              </ul>
            )}

            <button
              className={`${
                isFormFilled()
                  ? "bg-gradient-to-br from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 text-white"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              } relative group/btn block w-full rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]`}
              type="submit"
              disabled={!isFormFilled()}
            >
              Register →
            </button>

            <div className="flex flex-col space-y-4 mt-3">
              <button
                className="relative group/btn flex items-center justify-center px-4 w-full text-white bg-blue-600 hover:bg-blue-500 dark:bg-zinc-900 dark:text-white dark:hover:bg-blue-800 rounded-md h-10 font-medium shadow-input transition-all duration-300 ease-in-out transform hover:scale-105"
                type="submit"
              >
                <TfiMicrosoft className="h-5 w-5 mr-2" />
                <span className="text-sm font-semibold">
                  Sign in with Microsoft
                </span>
              </button>
              <button
                className="relative group/btn flex items-center justify-center px-4 w-full text-white bg-blue-600 hover:bg-blue-500 dark:bg-zinc-900 dark:text-white dark:hover:bg-blue-800 rounded-md h-10 font-medium shadow-input transition-all duration-300 ease-in-out transform hover:scale-105"
                type="submit"
              >
                <FcGoogle className="h-5 w-5 mr-2" />
                <span className="text-sm font-semibold">
                  Sign in with Google
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
