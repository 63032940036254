import React from "react";
import Logo from "../assets/images/Scale-secure-logo-white.png";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
const menuItems = [
  // {
  //   title: "Platform",
  //   items: [
  //     { label: "Blog", link: "#" },
  //     { label: "Partners", link: "/" },
  //     { label: "Integrations", link: "/" },
  //     { label: "Careers", link: "/" },
  //   ],
  // },
  // {
  //   title: "why ScaleSecure",
  //   items: [
  //     { label: "Blog", link: "#" },
  //     { label: "Partners", link: "/" },
  //     { label: "Integrations", link: "/" },
  //     { label: "Careers", link: "/" },
  //   ],
  // },
  {
    title: "Pricing",
    items: [
      { label: "Our Plans", link: "https://dev.scalesecure.io/plans" },
      // { label: "Partners", link: "/" },
      // { label: "Integrations", link: "/" },
      // { label: "Careers", link: "/" },
    ],
  },
  // {
  //   title: "Resources",
  //   items: [
  //     { label: "Blog", link: "/" },
  //     { label: "Partners", link: "/" },
  //     { label: "Integrations", link: "/" },
  //     { label: "Careers", link: "/" },
  //   ],
  // },
];

export default function Footer() {
  return (
    <div>
      {/* Banner */}
      <div className="bg-[#161a27] rounded-2xl h-80 flex flex-col justify-center items-center text-white mb-5">
        <h1 className="text-white font-body text-3xl text-center font-semibold mx-3">
          Ready to Get ScaleSecure?
        </h1>
        <div className="flex items-center justify-center px-2 py-4">
          {/* <Link to="/register"> */}
          <Link to="https://platform.scalesecure.io/register">
            <button className="px-8 justify-center items-center box-border bg-[#9647ff] text-white text-sm rounded-md font-body hover:bg-[#9647ff]/[0.8] hover:shadow-lg p-2">
              Get a Demo
            </button>
          </Link>
        </div>
      </div>

      {/* Footer Section */}
      <div className="bg-[#161a27] rounded-3xl flex items-center justify-center">
        <div className="mx-auto w-full max-w-screen-2xl p-4 py-6 lg:py-16 font-para text-white">
          <div className="md:flex md:justify-center mb-10">
            {/* Left Container */}
            <div className="mb-6 md:mb-0 flex flex-col sm:mr-10 md:w-2/6">
              <div className="md:m-2 m-3 flex items-center justify-center">
                <Link to="/" className="flex items-center">
                  <img
                    src={Logo}
                    className="h-24 me-1  saturate-150"
                    alt="ScaleSecure Logo"
                  />
                </Link>
              </div>

              <div className="md:mx-2 md:mt-6 md:mb-8 text-lg font-semibold text-white font-body">
                Your inbox just got Scale Secure.
              </div>

              <div className="md:mx-2 md:mt-2 md:mb-6 flex flex-col sm:flex-row">
                <input
                  type="email"
                  placeholder="Subscribe here..."
                  className="bg-[#F4F6F8] rounded-md text-[#161a27] md:text-sm font-para px-4 py-3 md:w-9/12 mb-3 sm:mb-0 placeholder-gray-700 placeholder-opacity-40"
                />
                <button className="md:w-3/12 bg-[#9647ff] text-white rounded-md font-para md:text-sm text-base py-3 md:py-2 hover:bg-[#6130a0] hover:shadow-lg transition-colors">
                  Submit
                </button>
              </div>
            </div>

            {/* Right Container */}
            <div className="grid grid-cols-1 gap-8 sm:gap-10 sm:grid-cols-2 md:grid-cols-2 md:ml-44 mt-8 font-para">
              {menuItems.map((category, index) => (
                <div key={index}>
                  <h2 className="mb-6 text-sm font-semibold text-white uppercase">
                    {category.title}
                  </h2>
                  <ul className="text-gray-400 font-medium space-y-2">
                    {category.items.map((item, i) => (
                      <li key={i}>
                        <Link to={item.link} className="hover:underline">
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8 w-5/6 " />

          <div className="sm:flex sm:items-center sm:justify-center mt-5">
            <div className="sm:flex sm:items-center sm:justify-between sm:w-2/3 font-para">
              <span className="text-sm text-gray-400 sm:text-center">
                © 2024{" "}
                <Link to="/" className="hover:underline">
                  ScaleSecure
                </Link>
                . All Rights Reserved.
              </span>
              <div className="flex mt-4 sm:justify-center sm:mt-0">
                {/* <a
                  to="https://www.instagram.com/scalesecure?igsh=ZG9lb3Ftbm1manVp"
                  className="text-gray-500 hover:text-white"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 8 19"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Facebook page</span>
                </a> */}
                <Link to="https://www.instagram.com/scalesecure?igsh=ZG9lb3Ftbm1manVp">
                  <FaInstagram className="text-gray-500 hover:text-white" />
                </Link>

                <Link
                  to="https://x.com/ScaleSecureio?t=mEwFp48BoF2yh80jbX0Jzw&s=08"
                  className="text-gray-500 hover:text-white ms-5"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 17"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Twitter page</span>
                </Link>

                {/* <a
                  to="#"
                  className="text-gray-500 hover:text-white ms-5"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.007-.866-.013-1.7-2.762.6-3.35-1.341-3.35-1.341-.454-1.154-1.107-1.461-1.107-1.461-.905-.62.07-.608.07-.608 1 .07 1.532 1.016 1.532 1.016.893 1.528 2.345 1.086 2.916.83.092-.648.35-1.086.635-1.337-2.206-.25-4.53-1.103-4.53-4.914A3.85 3.85 0 0 1 4.95 7.303c-.105-.25-.46-1.266.1-2.64 0 0 .863-.268 2.825 1.019A9.736 9.736 0 0 1 10 4.528a9.736 9.736 0 0 1 2.125.281c1.96-1.287 2.825-1.019 2.825-1.019.56 1.374.205 2.39.1 2.64a3.855 3.855 0 0 1 1.017 2.679c0 3.82-2.33 4.66-4.548 4.904.358.308.678.92.678 1.856 0 1.34-.013 2.421-.013 2.75 0 .268.178.576.685.478a10.001 10.001 0 0 0-3.131-19.662Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">GitHub account</span>
                </a> */}

                <Link
                  to="https://www.linkedin.com/company/scalesecure"
                  className="text-gray-500 hover:text-white ms-5"
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 17 13"
                  >
                    <path d="M15.803.001 1.205 0C.539 0 0 .536 0 1.195v10.61c0 .66.54 1.195 1.205 1.195h14.597c.666 0 1.204-.535 1.204-1.195V1.196C17 .536 16.47 0 15.803.001Zm-10.93 8.8H2.488V5.186h2.387V8.8Zm-1.194-4.1a1.388 1.388 0 1 1 0-2.775 1.388 1.388 0 0 1 0 2.775ZM14.51 8.8h-2.386V7.022c0-.423-.008-.967-.588-.967-.59 0-.68.46-.68.933v1.812H8.47V5.186h2.285v.493h.033c.319-.61 1.097-1.255 2.254-1.005 2.409.274 2.408 1.69 2.408 3.127V8.8Z" />
                  </svg>
                  <span className="sr-only">LinkedIn account</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
