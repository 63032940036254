import React from "react";
import { CardBody, CardContainer, CardItem } from "../utils/ThreeDCard/3D-card";
// import PhoneMockUp from "../assets/images/phone mockup.png";
import LandingImg from "../assets/images/landing-image.png";

export default function ThreeDCardDemo() {
  return (
    <CardContainer className="inter-var">
      {/* <CardBody className="custom-card-body group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1]"> */}
      <CardBody className="relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[20rem] md:w-[25rem] lg:w-[30rem] h-auto p-6">
        <img
          src={LandingImg}
          // src={PhoneMockUp}
          alt="PhoneMockUp"
          // className="md:h-full h-96 w-[80%]"
          className="model-img"
        />
      </CardBody>
    </CardContainer>
  );
}
