"use client";

import Card from "./Card";
import { useEffect, useRef } from "react";
import { useScroll } from "framer-motion";
import Lenis from "@studio-freight/lenis";
import Container from "../common/Container";
import image1 from "../assets/images/automated-testing.png";
import image2 from "../assets/images/Security-Test.png";
import image3 from "../assets/images/CHAT-Icon.svg";
import image4 from "../assets/images/diagram as code.jpg";
import image5 from "../assets/images/real-time-reporting.png";
import image6 from "../assets/images/supported systems.png";

const projects = [
  {
    title: "Load Testing",
    description:
      // "performance bottlenecks and ensure application readiness for peak loads.",
      "Ensuring your system can handle expected user load.",
    // src: "/service2.jpeg",
    src: image1,
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
    // color: "#FFFAFA",
    color: "rgb(239, 238, 251)",
    icons: [
      "/servicenowicons/customer-service-management.svg",
      "/servicenowicons/field-service-management.png",
      "/servicenowicons/hr-service-management.svg",
      "/servicenowicons/It-service-management.svg",
      "/servicenowicons/risk-management.png",
    ],
  },
  {
    title: "Security Testing",
    description:
      // "Proactively discover vulnerabilities and mitigate security risks",
      "Ensuring your applications and infrastructure are secure from vulnerabilities.",
    // "src": "/service3.jpeg",
    src: image2,
    link: "https://www.ignant.com/2022/09/30/clement-chapillon-questions-geographical-and-mental-isolation-with-les-rochers-fauves/",
    color: "#f0fca4",
    icons: [
      "/DigitalTransformationicons/cucumber-studio.png",
      "/DigitalTransformationicons/devicon--java-wordmark.svg",
      "/DigitalTransformationicons/devicon--nodejs-wordmark.svg",
      "/DigitalTransformationicons/selenium.png",
      "/DigitalTransformationicons/devicon--postgresql.svg",
      "/DigitalTransformationicons/devicon--python-wordmark.svg",
      "/DigitalTransformationicons/devicon--react-wordmark.svg",
      "/DigitalTransformationicons/kubernates.png",
    ],
  },
  {
    title: "LLM-Powered Recommendations",
    description:
      "Get AI-driven insights and scripts to optimize your application.",
    // src: "/service1.jpeg",
    src: image3,
    link: "https://www.ignant.com/2022/09/30/clement-chapillon-questions-geographical-and-mental-isolation-with-les-rochers-fauves/",
    color: "#fbfbfb",
    icons: [
      "/SAPicons/logos--sap.svg",
      "/SAPicons/sap-s4-hana.jpg",
      "/SAPicons/sap-anlytics.png",
      "/SAPicons/sap-ariba.jpg",
      "/SAPicons/sap-cloud-platform.jpg",
      "/SAPicons/sap06.jpeg",
      "/SAPicons/sap07.jpeg",
      "/SAPicons/sap-tech.jpg",
    ],
  },
  {
    title: "Architecture as a Service",
    description:
      // "Generate architecture diagrams aligned with your business use cases",
      "Providing custome architecture solution for any cloud platform based on your current and future scalling needs.",
    // src: "/salesforce.jpg",
    src: image4,
    link: "https://www.ignant.com/2023/10/28/capturing-balis-many-faces-zissou-documents-the-sacred-and-the-mundane-of-a-fragile-island/",
    // color: "#C2491D",
    color: "#F0F8FF",
    icons: [
      "/Salesforceicons/salesforce01.jpeg",
      "/Salesforceicons/cyberark-logo-v2.svg",
      "/Salesforceicons/devicon--salesforce.svg",
      "/Salesforceicons/SailPoint-logo.svg",
    ],
  },
  {
    title: "Real-Time Reporting",
    description:
      "View test results and visualizations instantly for immediate decision-making.",
    // src: "/salesforce.jpg",
    src: image5,
    link: "https://www.ignant.com/2019/03/13/a-photographic-series-depicting-the-uncertain-future-of-denmarks-treasured-coastlines/",
    // color: "#B62429",
    color: "#fAFAFA",
    icons: [
      "/DataAIicons/cloud_storage.svg",
      "/DataAIicons/Data-Lake-Storage-Gen1.svg",
      "/DataAIicons/databricks.svg",
      "/DataAIicons/google_bigquery-ar21.svg",
      "/DataAIicons/logos--aws-redshift.svg",
      "/DataAIicons/logos--aws-s3.svg",
      "/DataAIicons/logos--snowflake.svg",
    ],
  },
  {
    title: "Supported Systems",
    description:
      "Test REST APIs, WebSockets, Messaging Brokers, and Databases.",
    // src: "/salesforce.jpg",
    src: image6,
    link: "https://www.ignant.com/2019/03/13/a-photographic-series-depicting-the-uncertain-future-of-denmarks-treasured-coastlines/",
    // color: "#B62429",
    color: "#fAFAFA",
    icons: [
      "/DataAIicons/cloud_storage.svg",
      "/DataAIicons/Data-Lake-Storage-Gen1.svg",
      "/DataAIicons/databricks.svg",
      "/DataAIicons/google_bigquery-ar21.svg",
      "/DataAIicons/logos--aws-redshift.svg",
      "/DataAIicons/logos--aws-s3.svg",
      "/DataAIicons/logos--snowflake.svg",
    ],
  },
];

const ParallexCards = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  });
  return (
    <>
      {/* <Container>
        <div className="flex flex-col p-8 md:flex-row rounded-3xl gap-2">
          <div className="flex flex-col items-start justify-center w-full md:w-1/2 md:order-1">
            <h2 className="text-white text-2xl md:text-4xl leading-[30px] font-bold mb-4 text-start">
              Stay Ahead <br /> of the Curve
            </h2>
          </div>

          <div className="text-white flex flex-col items-start justify-center w-full md:w-1/2 md:order-1">
            <h2 className="text-sm md:text-lg leading-[30px] font-extralight mb-4 text-start letter-spacing-[-.0225rem]">
              Subscribe to our newsletter for scalability & security tips.
            </h2>
          </div>
        </div>
      </Container> */}
      <main ref={container} className="flex font-rubik flex-col items-center">
        {projects.map((project, i) => {
          const targetScale = 1 - (projects.length - i) * 0.05;
          return (
            <Card
              key={`p_${i}`}
              i={i}
              {...project}
              color={project.color}
              progress={scrollYProgress}
              range={[i * 0.25, 1]}
              targetScale={targetScale}
            />
          );
        })}
      </main>
    </>
  );
};

export default ParallexCards;
