import React from "react";
import Marquee from "react-fast-marquee";
import GXO from "../assets/images/gxo.png";
import Lululemon from "../assets/images/lululemon.png";
import Kavida from "../assets/images/kavida.png";
import Footlocker from "../assets/images/footlocker.png";
import Solarsys from "../assets/images/solarSyslogo2.jpeg";
import Johson from "../assets/images/johnson.png";
import BritishTelecom from "../assets/images/British_Telecom-logo.png";
import Sephora from "../assets/images/Sephora-Logo.png";

const logos = [
  { src: GXO, alt: "GXO" },
  { src: Lululemon, alt: "Lululemon" },
  { src: Kavida , alt: "Kavida" },
  { src: Footlocker, alt: "Footlocker" },
  { src: Solarsys, alt: "Solarsys" },
  // { src: Johson, alt: "Johson" },
  { src: Sephora, alt: "Sephora" },
  { src: BritishTelecom, alt: "BritishTelecom" },
];

export default function ContinuousScroll() {
  return (
    <Marquee gradient={false} speed={50} pauseOnHover={true} autoFill={true}>
      {logos.map((logo, index) => (
        <div key={index} className="flex items-center justify-center mx-8 overflow-hidden">
          <img
            loading="lazy"
            className="object-contain w-28 h-28 mx-auto transition-transform transform hover:scale-110 duration-300"
            src={logo.src}
            alt={logo.alt}
          />
        </div>
      ))}
    </Marquee>
  );
}
