import React from "react";
import ThreeDCard from "./ThreeDCard";
import { Link } from "react-router-dom";

export default function HeroSection() {
  return (
    <div className="custom-flex lg:mt-0 mt-14 text-[#ABABAB]">
      {/* Left container */}
      <div className="custom-content">
        <div className="flex flex-row items-center md:justify-start justify-center mb-4 md:mb-6">
          {/* App Store icon */}
        </div>

        <h2 className="custom-heading text-white text-2xl md:text-4xl">
          {/* Unlock Scalable & Secure Applications with Confidence */}A leading
          provider of scalability and security solution with AI based
          recommendations.
        </h2>

        <p className="custom-paragraph">
          Automated Load & Security Testing with AI-Powered Insights
        </p>

        {/* <Link to="/Register"> */}
        <Link to="https://platform.scalesecure.io/register">
          <button className="px-6 py-3 text-sm font-semibold rounded-md bg-[#9647ff] text-white">
            Get a Demo
          </button>
        </Link>
      </div>

      {/* Right container (3D card) */}
      <div className="w-full flex justify-center items-center mt-8 md:mt-0 md:w-1/2">
        <ThreeDCard />
      </div>
    </div>
  );
}
