import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion, AnimatePresence } from "framer-motion";

const Carousel = () => {
  const images = [
    {
      heading: "Devansh Bhanushali",
      summary: "ScaleSecure has helped us identify scalability issues and optimize our application performance. Devansh Digital Solutions, Director",
      backgroundImage:
        "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2620&q=80",
      image:
        "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
    },
    {
      heading: "Anshul Tated",
      summary: "The AI-powered recommendations are incredibly valuable for understanding our application's weaknesses. Bot2do, VP ",
      backgroundImage:
        "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
      image:
        "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
    },
    {
      heading: "Manthan Kataria",
      summary: "Thanks to ScaleSecure, our application scalability has improved dramatically, ensuring reliable performance even during our busiest periods. Bot2do , Director",
      backgroundImage:
        "https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2672&q=80",
      image:
        "https://images.unsplash.com/photo-1661961112951-f2bfd1f253ce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
    },
    {
      heading: "Yash Mav",
      summary: "The detailed reports and actionable insights from ScaleSecure have enabled us to fine-tune our application for maximum efficiency. Bot2do,Intern",
      backgroundImage:
        "https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2253&q=80",
      image:
        "https://images.unsplash.com/photo-1512756290469-ec264b7fbf87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
    },
    {
      heading: "Avdhut Nalawade",
      summary: "ScaleSecure's real-time monitoring and detailed analytics have empowered us to proactively address performance issues before they impact our users. Bot2do , Full-stack developer",
      backgroundImage:
        "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80",
      image:
        "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80",
    },
  ];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [currentImage, images.length]);

  const handlePreviousClick = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  return (
    <div className="relative w-full h-screen flex justify-center items-center overflow-hidden">
      <AnimatePresence>
        <motion.div
          key={currentImage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute w-full h-full bg-cover bg-center rounded-3xl"
          style={{
            backgroundImage: `url(${images[currentImage].backgroundImage})`,
          }}
        />
      </AnimatePresence>

      <div>
        <div
          className="flex justify-center items-center absolute p-4 flex-row ease-in-out space-x-2"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div>
            <div className="bg-[#9647ff] rounded-2xl p-1 cursor-pointer">
              <ArrowBackIcon onClick={handlePreviousClick} style={{ color: "white" }} />
            </div>
          </div>

          <div className="w-11/12 bg-[#11141f] flex items-center justify-center flex-col rounded-2xl p-3">
            <div className="">
              <img
                src={images[currentImage].image}
                alt="Carousel Image"
                className="object-cover w-32 h-32 rounded-full m-4"
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <div>
                <h2 className="text-2xl text-white">
                  {images[currentImage].heading}
                </h2>
              </div>
              <div className="w-5/6 text-center font-para text-white">
                <p>{images[currentImage].summary}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-[#9647ff] rounded-2xl p-1 cursor-pointer">
              <ArrowForwardIcon onClick={handleNextClick} style={{ color: "white" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
