import React, { useEffect, useState } from "react";
import PricingSection from "../components/PricingSection";
import { PureIncrement } from "pure_counter";
import CountUp from "react-countup";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import Pricingcomparison from "../components/Pricecomparison";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Carousel from "../components/Carousel";
import CarouselPhone from "../components/CarouselPhone";
export default function Pricing() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the viewport
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const [activeIndex, setActiveIndex] = useState(null); // State for tracking active dropdown

  // Function to toggle the active dropdown index
  const toggleText = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Array of FAQ questions and answers
  const faqData = [
    {
      question: "What types of load tests can ScaleSecure perform?",
      answer:
        "ScaleSecure can perform a range of load tests, including soak tests to evaluate system performance under sustained loads, stress tests to assess system stability under extreme conditions, spike tests to measure response to sudden traffic spikes, smoke tests to ensure basic functionality, and tests specific to RESTful and GraphQL APIs to validate their performance and functionality.",
    },
    {
      question: "What security vulnerabilities does ScaleSecure detect?",
      answer:
        "ScaleSecure detects common vulnerabilities like SQL injection, cross-site scripting (XSS), insecure authentication mechanisms, inadequate data encryption, and known software vulnerabilities.",
    },
    {
      question: "How does the LLM model generate recommendations?",
      answer:
        "The LLM (Language Model) analyzes historical data and current system metrics to identify patterns and trends. It then generates recommendations based on best practices and performance optimization algorithms to improve system efficiency and security.",
    },
    {
      question: "Do I need technical expertise to use ScaleSecure?",
      answer:
        "No, ScaleSecure is designed for ease of use. Its intuitive interface and automated processes require minimal technical expertise, allowing users of all levels to execute tests effortlessly and interpret results effectively.",
    },
    {
      question: "What subscription plans do you offer?",
      answer:
        "For details on subscription plans, please visit our pricing page",
    },
  ];
  const [startCounting, setStartCounting] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger animation once
    threshold: 0.5,
  });

  useEffect(() => {
    // Start counting when inView becomes true
    if (inView) {
      setStartCounting(true);
    }
  }, [inView]);

  return (
    <div className="font-body w-full h-full bg-[#11141f] lg:overflow-visible overflow-hidden relative">
      {/* Coming Soon Overlay */}
      <div className="fixed inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70 backdrop-blur-md z-50">
        <img
          src="./comingsoon.svg"
          alt="Coming Soon"
          className="w-1/2 md:w-1/3"
        />
        <h1 className="text-gray-100 font-body text-2xl my-4">
          Comming Soon...
        </h1>
      </div>
      {/* Navbar Section */}
      <div className="px-5 pb-2.5">
        <Navbar />
      </div>
      {/* Navbar Section */}

      {/* Pricing Section  */}
      <div className="flex flex-col justify-center items-center">
        <PricingSection />
        <div className="px-5 py-2 flex items-center">
          <div className="bg-[#161a27] rounded-3xl h-80 max-w-screen-xl mt-10 flex flex-col justify-center items-center  text-[#ABABAB] mb-5 ">
            <h1 className=" text-black font-body text-center text-3xl font-semibold mx-3">
              Custom Pricing Model
            </h1>
            <div className="mx-3 mt-5 text-center">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore
              ad possimus commodi esse, debitis rem voluptatem nostrum harum ab
              labore .
            </div>
            <div className=" flex items-center justify-center  px-2 py-4">
              {/* <Link to="/register"> */}
              <Link to="https://platform.scalesecure.io/register">
                <button className="px-8 justify-center items-center box-border bg-[#9647ff] text-black text-sm rounded-md font-body hover:bg-[#9647ff]/[0.8] hover:shadow-lg p-2">
                  Get a Demo
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Comparison Section */}
      <div>
        <Pricingcomparison />
      </div>

      {/*  Counter Section */}
      <div
        ref={ref}
        className="flex flex-col md:items-center justify-center text-left space-y-9 py-5 m-5 custom-font"
      >
        <p className="text-[#161a27]">We've processed over</p>
        <div className="flex">
          <h1 className="font-extrabold md:text-8xl text-2xl">$</h1>
          <CountUp
            start={startCounting ? 0 : null}
            end={200}
            duration={5}
            className="purecounter font-extrabold md:text-8xl text-2xl"
          />
          <h1 className="font-extrabold md:text-8xl text-2xl">,000,000</h1>
        </div>
        <div>In subscription GMV, and we're just getting started.</div>
      </div>
      {/* Carousel section */}
      <div>
        {/* Phone Element */}
        {/* <div
          className={
            width <= 768
              ? "w-full h-full flex justify-center items-center px-5 py-2.5"
              : "hidden"
          }
        >
          <div className="w-full h-full flex justify-center items-center">
            <CarouselPhone />
          </div>
        </div>

        <div
          className={
            width > 768
              ? "w-full h-full flex justify-center items-center px-5 py-2.5"
              : "hidden"
          }
        >
          <div className="w-full h-full flex justify-center items-center">
            <Carousel />
          </div>
        </div> */}

        <div>
          {/* Phone Element */}
          <div className="md:hidden">
            <div className="w-full h-full flex justify-center items-center px-5 py-2.5">
              <CarouselPhone />
            </div>
          </div>
          {/* Desktop Element */}
          <div className="hidden md:block">
            <div className="w-full h-full flex justify-center items-center px-5 py-2.5">
              <Carousel />
            </div>
          </div>
        </div>
      </div>
      {/* Carousel Section */}

      {/* FAQ Section */}
      <div className="flex justify-center items-center my-10">
        <div className="flex flex-col justify-center items-center px-5 py-2.5 custom-font space-y-10">
          <div className="font-extrabold text-5xl p-6 text-center text-gray-800">
            Frequently Asked Questions
          </div>
          {/* FAQ Items */}
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-blue-50 to-blue-100 flex flex-col space-y-2.5 rounded-xl w-full max-w-2xl p-5 my-2 shadow-lg transition-all duration-300 transform hover:scale-105 cursor-pointer"
              onClick={() => toggleText(index)}
            >
              <div className="flex items-center justify-between text-left">
                <div className="flex font-semibold text-gray-800">
                  {faq.question}
                </div>
                <motion.div
                  animate={{ rotate: activeIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex bg-gray-200 p-1 rounded-full"
                >
                  <ArrowDropDownIcon />
                </motion.div>
              </div>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="mt-2 text-left overflow-hidden"
                  >
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-sm text-gray-600"
                    >
                      {faq.answer}
                    </motion.span>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
      {/* FAQ Section */}

      {/* Footer Section  */}
      <div className="px-5 py-2.5">
        <Footer />
      </div>
      {/* Footer Section  */}
    </div>

    // <main className="w-full">
    //   <div className="pb-2.5">
    //     <Navbar />
    //   </div>
    //   <div className="flex flex-col items-center justify-center h-screen text-center">
    //     <h1 className="text-black text-2xl mb-4">Coming Soon</h1>
    //     <img
    //       src="./comingsoon.svg"
    //       alt="Error"
    //       width={300}
    //       height={170}
    //       className="mb-4"
    //     />
    //     <p className="text-black text-2xl mt-8 mb-4">
    //       Need an early access to our success stories? <br />
    //       Please fill out your email address. Our team will reach out to you.
    //     </p>
    //     <div className="form-control h-10">
    //       <form
    //         action="https://solarsystek.us18.list-manage.com/subscribe/post?u=052f7a295fc398c5cb85561bc&amp;id=5f0db13330&amp;f_id=0080c2e1f0"
    //         method="post"
    //         id="mc-embedded-subscribe-form"
    //         name="mc-embedded-subscribe-form"
    //         class="form-control-container"
    //         // target='_blank'
    //       >
    //         <div className="flex items-center mt-4 text-gray-300 h-10">
    //           <input
    //             type="email"
    //             id="email"
    //             name="email"
    //             className="w-full md:w-96 sm:max-w-xs py-2 px-4 border bg-transparent border-[#D9D9D9] rounded-md mr-4"
    //             placeholder="Enter your email"
    //           />
    //           <button
    //             className="my-14 text-xl px-6 py-3 rounded-md text-black font-semibold bg-gradient-to-b from-[#F3DF7B] via-[#F7EF8A] to-[#f3c65c] focus:outline-none"
    //             type="submit"
    //           >
    //             Send
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //     {/* <Link href="/" className="mt-4 text-red-400 underline hover:text-red-700">
    //           Click here to Home page
    //         </Link> */}
    //   </div>
    //   <Footer />
    // </main>
  );
}
