import React from "react";
import { useState } from "react";
import Bentogridimage0 from "../assets/images/bento_1.png";
import Bentogridimage5 from "../assets/images/bento_4.png";
import Bentogridimage6 from "../assets/images/bento_6.png";
import Bentogridimage1 from "../assets/images/bento-grid-image1.png";
import Bentogridimage2 from "../assets/images/bento-grid-image2.png";
import Bentogridimage3 from "../assets/images/bento-grid-image3.png";
import Bentogridimage4 from "../assets/images/bento-grid-image4.png";

const Bento = () => {
  const [gridOneHovered, setgridOneHovered] = useState(false);
  const [gridTwoHovered, setgridTwoHovered] = useState(false);
  const [gridThreeHovered, setgridThreeHovered] = useState(false);
  const [gridFourHovered, setgridFourHovered] = useState(false);
  const [gridFiveHovered, setgridFiveHovered] = useState(false);
  const [gridSixHovered, setgridSixHovered] = useState(false);

  return (
    <div className="flex flex-col bg-white rounded-2xl justify-center items-center w-full h-full">
      {/* Written Section  */}
      <div className="flex flex-row w-7/12 justify-center items-center pt-5 space-x-5">
        <div className="w-1/2 ">
          <h1 className="font-body text-2xl font-bold">
            The subscription features you need, all in one place
          </h1>
        </div>
        <div className="w-1/2 flex flex-col items-start font-para">
          <p>
            ScaleSecure pairs flexible subscription offerings with analytics,
            24/7 support, and powerful post-purchase engagement tools, giving
            you what you need to grow subscribers with confidence.
          </p>
          <button className="px-8 box-border bg-[#161a27] text-white text-sm rounded-md font-body hover:bg-black/[0.8] hover:shadow-lg p-2 my-3">
            Tour the Platform
          </button>
        </div>
      </div>
      <div className="h-7/12 w-[1000px] grid grid-cols-3 gap-5 py-8">
        {/* Box-1 */}
        <div
          className={`row-span-1 bg-${
            gridOneHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridOneHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridOneHovered(true)}
          onMouseLeave={() => setgridOneHovered(false)}
        >
          <img src={Bentogridimage1} alt="1" className="rounded-3xl" />
          <div className=" text-left px-6 py-5">
            <h1 className="font-medium custom-font ">Automated Testing</h1>
            <p>
              Effortlessly run comprehensive load and security tests with
              minimal setup, saving you time and effort.
            </p>
          </div>
        </div>
        {/* Box-2 */}
        <div
          className={`row-span-1 bg-${
            gridTwoHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridTwoHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridTwoHovered(true)}
          onMouseLeave={() => setgridTwoHovered(false)}
        >
          <img src={Bentogridimage4} alt="2" className="rounded-3xl" />
          <div className=" text-left px-6 py-5">
            <h2 className="custom-font font-medium">
              AI-powered Recommendations
            </h2>
            <p>
              Receive intelligent, data-driven insights to fine-tune your
              testing parameters and uncover hidden vulnerabilities.
            </p>
          </div>
        </div>
        {/* Box-3 */}
        <div
          className={`row-span-2 bg-${
            gridThreeHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridThreeHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridThreeHovered(true)}
          onMouseLeave={() => setgridThreeHovered(false)}
        >
          <img
            // src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65eeaef2a22a2ed701312fe5_4.%20Smartrr%20Retention%20Actions-p-500.png"
            src={Bentogridimage0}
            alt="3"
            className="rounded-3xl"
          />
          <div className=" text-left px-5 py-5">
            <h2 className="custom-font font-medium">
              Effortless Security Fixes
            </h2>
            <p>
              Leverage AI-suggested code snippets to address security issues
              quickly and efficiently.
            </p>
          </div>
        </div>
        {/* Box-4 */}
        <div
          className={`row-span-2 bg-${
            gridFourHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridFourHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridFourHovered(true)}
          onMouseLeave={() => setgridFourHovered(false)}
        >
          <img
            // src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65eeaeadfb1009ccce30ae0e_5.%20Analytics%20%26%20Insights.svg"
            src={Bentogridimage6}
            alt="4"
            className="rounded-3xl object-contain"
          />
          <div className=" text-left px-5 py-5 ">
            <h2 className="custom-font font-medium ">Clear Documentation</h2>
            <p>
              Generate detailed PDF reports that clearly communicate your
              testing results, making team collaboration seamless.
            </p>
          </div>
        </div>
        {/* Box-5 */}
        <div
          className={`row-span-1 bg-${
            gridFiveHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridFiveHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridFiveHovered(true)}
          onMouseLeave={() => setgridFiveHovered(false)}
        >
          <img src={Bentogridimage3} alt="5" className="rounded-3xl" />
          <div className=" text-left px-5 py-5">
            <h2 className="custom-font font-medium">
              Streamlined Production Releases
            </h2>
            <p>
              Use AI-generated benchmark certificates to confidently deploy your
              applications, ensuring stability and security.
            </p>
          </div>
        </div>
        {/* Box-6 */}
        <div
          className={`row-span-1 col-span-2 bg-${
            gridSixHovered ? "white" : "indigo-200"
          } rounded-3xl transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300`}
          style={{
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: gridSixHovered
              ? "13px 11px 0px rgba(3, 25, 61, 1)"
              : "none",
          }}
          onMouseEnter={() => setgridSixHovered(true)}
          onMouseLeave={() => setgridSixHovered(false)}
        >
          <div className="flex flex-row-reverse space-x-5">
            <div className="w-full">
              <img
                src={Bentogridimage5}
                alt="6"
                className="rounded-3xl max-h-[250px] object-cover w-full"
              />{" "}
            </div>
            <div className="w-[98%] py-5">
              <h2 className="custom-font font-medium">
                Enhanced Team Collaboration
              </h2>
              <p>
                Share comprehensive test reports effortlessly,fostering better
                communication and collaboration within your team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bento;
