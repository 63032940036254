import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const WhyScale = () => {
  const [leftHovered, setLeftHovered] = useState(false);
  const [rightTopHovered, setRightTopHovered] = useState(false);
  const [rightBottomHovered, setRightBottomHovered] = useState(false);

  return (
    <div className="h-full rounded-2xl flex flex-row bg-[#161a27] space-x-1 p-6 custom-font">
      {/* Left Container */}
      <div className="w-1/2 flex flex-col items-center justify-start">
        <p className="  text-white text-5xl custom-font mt-20 mb-12  w-[75%]">
        Why Choose ScaleSecure.io?
        </p>
        <div className="flex items-center justify-center">
          <div
            className={`bg-${
              leftHovered ? "white" : "white"
            } rounded-2xl w-[85%] flex flex-col item-center p-5 transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300 `}
            style={{
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: leftHovered
                ? "13px 11px 0px rgba(216, 231, 240, 1)"
                : "none",
            }}
            onMouseEnter={() => setLeftHovered(true)}
            onMouseLeave={() => setLeftHovered(false)}
          >
                  <p className="text-xl"><b>AI Insights for Load Testing.</b></p>
            <p>
            Our AI analyzes data to pinpoint performance bottlenecks and security threats. It recommends optimizations like query tuning and suggests security measures such as patching vulnerabilities. Predictive analytics anticipate future issues, advising on infrastructure scaling.
            </p>

            <motion.button
              className="p-2 w-full lg:w-1/4 rounded-full tracking-widest bg-[#b4c4dc] dark:text-neutral-200 flex items-center justify-center hover:bg-[#9647ff] transition duration-500 ease-in-out space-x-2 m-5 hover:flex-row-reverse"
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                className="rounded-full bg-[#9647ff] p-2"
                whileHover={{ x: 2, scale: 1.1, rotate: -10 }}
                transition={{
                  duration: 0.3,
                  ease: [0.175, 0.885, 0.32, 1.275],
                }} // Adjusted easing function
              >
                <ArrowForwardIcon className="text-white" />
              </motion.div>
              <motion.div
                className="text-white"
                whileHover={{ x: -2 }}
                transition={{
                  duration: 0.3,
                  ease: [0.175, 0.885, 0.32, 1.275],
                }} // Adjusted easing function
              >
                Let's Talk
              </motion.div>
            </motion.button>

            <img
              src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* Right Container */}
      <div className="w-1/2 flex flex-col justify-center item-center  ">
        <div className="flex flex-col space-y-6 h-auto">
          <div
            className={`bg-${
              rightTopHovered ? "white" : "white"
            } rounded-2xl p-3 w-[85%] transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300 `}
            style={{
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: rightTopHovered
                ? "13px 11px 0px rgba(216, 231, 240, 1)"
                : "none",
            }}
            onMouseEnter={() => setRightTopHovered(true)}
            onMouseLeave={() => setRightTopHovered(false)}
          >
            <p className="text-xl"><b>Automated tests, minimal expertise needed.</b></p>
            <p>
            Our testing solution automates processes, minimizing the need for technical proficiency. Users can execute tests effortlessly, regardless of their technical background. With intuitive interfaces and simplified workflows, our platform streamlines testing procedures, enabling teams to focus on results rather than technical intricacies.
            </p>
          

            <motion.button
              className="p-2 w-1/4 rounded-full tracking-widest bg-[#b4c4dc] hover:text-black dark:text-neutral-200 flex items-center justify-center hover:bg-[#9647ff] transition duration-300 space-x-2 m-5 hover:flex-row-reverse"
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                className="rounded-full bg-[#9647ff] p-2"
                whileHover={{ x: 2, scale: 1.1, rotate: -10 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <ArrowForwardIcon className="text-white" />
              </motion.div>
              <motion.div className="text-white" whileHover={{ x: -2 }}>
                Let's Talk
              </motion.div>
            </motion.button>

            <img
              src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
              alt=""
            />
          </div>
          <div
            className={`bg-${
              rightBottomHovered ? "white" : "white"
            } rounded-2xl w-[85%] p-3 transform hover:-translate-x-2 hover:-translate-y-2 transition-transform duration-300 `}
            style={{
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: rightBottomHovered
                ? "13px 11px 0px rgba(216, 231, 240, 1)"
                : "none",
            }}
            onMouseEnter={() => setRightBottomHovered(true)}
            onMouseLeave={() => setRightBottomHovered(false)}
          >
                  <p className="text-xl"><b>Instant test results for data-driven decisions.</b></p>
            <p>
            Access real-time data insights to make informed decisions swiftly. Our platform delivers immediate test results, empowering you to adapt and optimize strategies in real-time. With actionable insights at your fingertips, you can iteratively refine your approach, ensuring peak performance and efficiency.
            </p>
            {/* <button className="p-2 w-1/4 rounded-full tracking-widest bg-[#b4c4dc]  hover:text-black dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 space-x-2 m-5 hover:flex-row-reverse hover:duration-300">
              <div className="rounded-full text-black bg-[#9647ff] transform transition-transform duration-300">
                <ArrowForwardIcon />
              </div>
              <div className="text-black">Lets Talk</div>
            </button> */}

            <motion.button
              className="p-2 w-1/4 rounded-full tracking-widest bg-[#b4c4dc] hover:text-black dark:text-neutral-200 flex items-center justify-center hover:bg-[#9647ff] transition duration-300 space-x-2 m-5 hover:flex-row-reverse"
              whileHover={{ scale: 1.05 }}
            >
              <motion.div
                className="rounded-full bg-[#9647ff] p-2"
                whileHover={{ x: 2, scale: 1.1, rotate: -10 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <ArrowForwardIcon className="text-white" />
              </motion.div>
              <motion.div className="text-white" whileHover={{ x: -2 }}>
                Let's Talk
              </motion.div>
            </motion.button>

            <img
              src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyScale;
