import React, { useState } from "react";
import { Label } from "../utils/Login/Label";
import { Input } from "../utils/Login/Input";
import cn from "../utils/cn";
import Logo from "../assets/images/Scale-secure-logo-white.png";

import { IconBrandGithub, IconBrandGoogle } from "@tabler/icons-react";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPasswordValid()) {
      console.log("Form submitted");
    } else {
      console.log("Password validation failed");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValid({
      length,
      uppercase,
      number,
      specialChar,
    });

    if (!length) {
      setPasswordError("Password must be at least 8 characters long.");
    } else if (!uppercase) {
      setPasswordError("Password must contain at least one uppercase letter.");
    } else if (!number) {
      setPasswordError("Password must contain at least one number.");
    } else if (!specialChar) {
      setPasswordError("Password must contain at least one special character.");
    } else {
      setPasswordError("");
    }
  };

  const isPasswordValid = () => {
    return (
      passwordValid.length &&
      passwordValid.uppercase &&
      passwordValid.number &&
      passwordValid.specialChar
    );
  };

  const isFormValid = () => {
    return username.trim() !== "" && isPasswordValid();
  };

  return (
    <div className="flex flex-col md:flex-row h-screen custom-font bg-[#11141f]">
      {/* Left side - Scale Secure logo */}
      <div className="md:w-1/2 bg-[#161a27] flex items-center justify-center">
        <img src={Logo} alt="Scale Secure" className="w-96" />
      </div>
      {/* Right side - Login form */}
      <div className="md:w-1/2 md:mt-0 mt-4 bg-[#11141f] font-primarybold flex items-center justify-center">
        <div className="max-w-md w-full mx-auto md:mx-0 rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-[#11141f] dark:bg-gray-800">
          <h2 className="font-primarybold text-xl md:text-2xl text-white dark:text-gray-200">
            Welcome to Scale Secure
          </h2>
          <p className="text-white dark:text-gray-400 text-md md:text-lg max-w-sm mt-2">
            Login to Scale Secure to access your account
          </p>

          <form className="my-8" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
              <LabelInputContainer>
                <Label className="text-white" htmlFor="username">Username</Label>
                <Input
                  id="username"
                  placeholder="Username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </LabelInputContainer>
            </div>

            <LabelInputContainer className="mb-4 relative">
              <Label className="text-white" htmlFor="password">Password</Label>
              <Input
                id="password"
                placeholder="••••••••"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-6 text-gray-600 dark:text-gray-400 flex items-center focus:outline-none"
              >
                {passwordVisible ? (
                  <AiFillEyeInvisible size={24} />
                ) : (
                  <AiFillEye size={24} />
                )}
              </button>
            </LabelInputContainer>

            {password && (
              <ul className="mb-4">
                <li
                  className={`text-sm flex items-center ${
                    passwordValid.length ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {passwordValid.length ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must be at least 8 characters long.
                </li>
                <li
                  className={`text-sm flex items-center ${
                    passwordValid.uppercase ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {passwordValid.uppercase ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one uppercase letter.
                </li>
                <li
                  className={`text-sm flex items-center ${
                    passwordValid.number ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {passwordValid.number ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one number.
                </li>
                <li
                  className={`text-sm flex items-center ${
                    passwordValid.specialChar
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {passwordValid.specialChar ? (
                    <AiOutlineCheck className="mr-2" />
                  ) : (
                    <AiOutlineClose className="mr-2" />
                  )}
                  Password must contain at least one special character.
                </li>
              </ul>
            )}

            <button
              className={`${
                isFormValid()
                  ? "bg-gradient-to-br from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 text-white"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              } relative group/btn block w-full rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]`}
              type="submit"
              disabled={!isFormValid()}
            >
              Login →
              <BottomGradient />
            </button>

            <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full" />

            <div className="flex flex-col space-y-4 mt-3">
              <button
                className=" relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
                type="submit"
              >
                <IconBrandGithub className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                  Microsoft
                </span>
                <BottomGradient />
              </button>
              <button
                className=" relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
                type="submit"
              >
                <IconBrandGoogle className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
                <span className="text-neutral-700 dark:text-neutral-300 text-sm">
                  Google
                </span>
                <BottomGradient />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export default Login;
