import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function WhyScalePhone() {
  return (
    <div className=" w-full h-full rounded-3xl flex flex-col bg-[#161a27] font-body">
      <div className="text-white font-body text-3xl text-center p-5">
        Why ScaleSecure is a no-brainer.
      </div>

      <div className="bg-[white] rounded-[2rem] flex flex-col item-center p-7 mx-5 my-2.5">
        <p className=" font-body font-extrabold text-lg mb-2.5 text-[#161a27]">
        AI Insights for Load Testing.
        </p>
        <p className="text-sm">
        Our AI analyzes data to pinpoint performance bottlenecks and security threats. It recommends optimizations like query tuning and suggests security measures such as patching vulnerabilities. Predictive analytics anticipate future issues, advising on infrastructure scaling.
        </p>
        <button className="p-2 w-3/5 rounded-full tracking-widest bg-[#c9daf3]  hover:text-black dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 hover:flex-row-reverse hover:duration-300 mt-2">
          <div className="rounded-full text-white bg-[#9647ff] transform transition-transform duration-300">
            <ArrowForwardIcon />
          </div>
          <div className="text-white">Lets Talk</div>
        </button>
        <img
          src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
          alt=""
        />
      </div>

      <div className="bg-[white] rounded-[2rem] flex flex-col item-center p-7 mx-5 my-2.5">
        <p className=" font-body font-extrabold text-lg mb-2.5 text-[#161a27]">
        Automated tests, minimal expertise needed.
        </p>
        <p className="text-sm">
        Our testing solution automates processes, minimizing the need for technical proficiency. Users can execute tests effortlessly, regardless of their technical background. With intuitive interfaces and simplified workflows, our platform streamlines testing procedures, enabling teams to focus on results rather than technical intricacies.
        </p>
        <button className="p-2 w-3/5 rounded-full tracking-widest bg-[#c9daf3]  hover:text-black dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 hover:flex-row-reverse hover:duration-300 mt-2">
          <div className="rounded-full text-white bg-[#9647ff] transform transition-transform duration-300">
            <ArrowForwardIcon />
          </div>
          <div className="text-white">Lets Talk</div>
        </button>
        <img
          src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
          alt=""
        />
      </div>

      <div className="bg-[white] rounded-[2rem] flex flex-col item-center p-7 mx-5 my-2.5">
        <p className=" font-body font-extrabold text-lg mb-2.5 text-[#161a27]">
        Instant test results for data-driven decisions.
        </p>
        <p className="text-sm">
        Access real-time data insights to make informed decisions swiftly. Our platform delivers immediate test results, empowering you to adapt and optimize strategies in real-time. With actionable insights at your fingertips, you can iteratively refine your approach, ensuring peak performance and efficiency.
        </p>
        <button className="p-2 w-3/5 rounded-full tracking-widest bg-[#c9daf3]  hover:text-black dark:text-neutral-200 flex hover:bg-[#9647ff] transition duration-200 hover:flex-row-reverse hover:duration-300 mt-2">
          <div className="rounded-full text-white bg-[#9647ff] transform transition-transform duration-300">
            <ArrowForwardIcon />
          </div>
          <div className="text-white">Lets Talk</div>
        </button>
        <img
          src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65ea25d04a36a4fafaf8b5e0_SVG-1.svg"
          alt=""
        />
      </div>
    </div>
  );
}
