import React from "react";
import Bentogridimage1 from "../assets/images/bento-grid-image1.png"
import Bentogridimage2 from "../assets/images/bento-grid-image2.png"
import Bentogridimage3 from "../assets/images/bento-grid-image3.png"
import Bentogridimage4 from "../assets/images/bento-grid-image4.png"

const BentoPhone = () => {
  return (
    <div className="bg-white rounded-3xl py-6">
      <div className="px-7 py-2.5">
        {/* Written Section  */}
        <div className="flex flex-col justify-start items-start py-5 ">
          <h1 className="custom-font font-bold text-2xl md:text-3xl text-start">
            The subscription features you need, all in one place
          </h1>
        </div>
        <div className="flex">
          <p>
            ScaleSecure pairs flexible subscription offerings with analytics, 24/7
            support, and powerful post-purchase engagement tools, giving you
            what you need to grow subscribers with confidence.
          </p>
        </div>
        <div className="my-5">
          <button className="px-8 justify-center items-center box-border bg-[#161a27] text-white text-sm rounded-md font-body hover:bg-black/[0.8] hover:shadow-lg p-3">
            Tour the Platform
          </button>
        </div>
      </div>
      {/* Box-1 */}
      <div className="px-5 py-2.5">
        <div className="row-span-1 bg-indigo-200 rounded-3xl">
          <img
            src={Bentogridimage1}
            alt="1"
            className="rounded-3xl object-fill w-full"
          />
          <div className=" text-left px-6 py-5">
            <h1 className="font-medium custom-font text-[16px] md:text-lg">
              Automated Testing
            </h1>
            <p className="text-sm md:text-[16px]">
              Effortlessly run comprehensive load and security tests with
              minimal setup, saving you time and effort.
            </p>
          </div>
        </div>
      </div>

      {/* Box-2 */}
      <div className="px-5 py-2.5">
        <div className=" row-span-1 bg-indigo-200 rounded-3xl">
          <img
            src={Bentogridimage4}
            alt="2"
            className="rounded-3xl object-fill w-full"
          />
          <div className=" text-left px-6 py-5">
            <h2 className="custom-font font-medium text-[16px] md:text-lg">
              AI-powered Recommendations
            </h2>
            <p className="text-sm md:text-[16px]">
              Receive intelligent, data-driven insights to fine-tune your
              testing parameters and uncover hidden vulnerabilities.
            </p>
          </div>
        </div>
      </div>

      {/* Box-3 */}
      <div className="px-5 py-2.5">
        <div className=" row-span-2 bg-indigo-200 rounded-3xl">
          <img
            src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65eeaef2a22a2ed701312fe5_4.%20Smartrr%20Retention%20Actions-p-500.png"
            alt="3"
            className="rounded-3xl object-fill w-full"
          />
          <div className=" text-left px-5 py-5">
            <h2 className="custom-font font-medium text-[16px] md:text-lg">
              Effortless Security Fixes
            </h2>
            <p className="text-sm md:text-[16px]">
              Leverage AI-suggested code snippets to address security issues
              quickly and efficiently.
            </p>
          </div>
        </div>
      </div>

      {/* Box-4 */}
      <div className="px-5 py-2.5">
        <div className=" row-span-2 bg-indigo-200 rounded-3xl">
          <img
            src="https://assets-global.website-files.com/65ea25d04a36a4fafaf8b592/65eeaeadfb1009ccce30ae0e_5.%20Analytics%20%26%20Insights.svg"
            alt="4"
            className="rounded-3xl object-fill w-full"
          />
          <div className=" text-left px-5 py-5">
            <h2 className="custom-font font-medium text-[16px] md:text-lg">
              Clear Documentation
            </h2>
            <p className="text-sm md:text-[16px]">
              Generate detailed PDF reports that clearly communicate your
              testing results, making team collaboration seamless.
            </p>
          </div>
        </div>
      </div>

      {/* Box-5 */}
      <div className="px-5 py-2.5">
        <div className="row-span-1 bg-indigo-200 rounded-3xl">
          <img
            src={Bentogridimage3}
            alt="5"
            className="rounded-3xl object-fill w-full"
          />
          <div className=" text-left px-5 py-5">
            <h2 className="custom-font font-medium text-[16px] md:text-lg">
              Streamlined Production Releases
            </h2>
            <p className="text-sm md:text-[16px]">
              Use AI-generated benchmark certificates to confidently deploy your
              applications, ensuring stability and security.
            </p>
          </div>
        </div>
      </div>

      {/* Box-6 */}
      <div className="px-5 py-2.5">
        <div className="row-span-1 col-span-2 bg-indigo-200 rounded-3xl">
          <div className="flex sm:flex-row-reverse space-x-5 flex-col-reverse">
            <div className=" w-full  ">
              <img
                src={Bentogridimage2}
                alt="6"
                className="rounded-3xl "
              />
            </div>
            <div className="text-left px-5 py-5">
              <h2 className="custom-font font-medium text-[16px] md:text-lg">
                Enhanced Team Collaboration
              </h2>
              <p className="text-sm md:text-[16px]">
                Share comprehensive test reports effortlessly, fostering better
                communication and collaboration within your team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BentoPhone;
