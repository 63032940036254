"use client";
// import Image from "next/image";
import "./style.css";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Card = ({
  title,
  description,
  progress,
  range,
  targetScale,
  src,
  icons,
  color,
  i,
}) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });

  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1]);
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div className="cardContainer font-rubik">
      <motion.div
        className="card w-full lg:w-[1000px]"
        style={{
          backgroundColor: color,
          scale,
          top: `calc(-5vh + ${i * 25}px)`,
        }}
      >
        <div className="flex flex-col gap-4 mt-8 md:flex-row lg:flex-row xl:flex-row body">
          {/* Title, Description, and Link */}
          <div className="relative w-full md:w-full lg:w-1/2 xl:w-1/2">
            <div className="relative w-full">
              <h2 className="text-xl font-bold text-center md:text-xl">
                {title}
              </h2>
              <p className="text-[16px] leading-5 mt-2 md:mt-4 text-black md:text-xl">
                {description}
              </p>
            </div>

            <motion.button
              className="p-2 rounded-full tracking-widest bg-gray-200 flex items-center hover:bg-[#9647ff] transition duration-200 space-x-2 m-5 hover:flex-row-reverse hover:duration-300"
              whileHover={{ scale: 1 }}
            >
              <div className="rounded-full bg-[#9647ff] p-2 transform transition-transform duration-300">
                <ArrowForwardIcon sx={{ color: 'white' }} />
              </div>
              <div className="text-black hover:text-white">Why Launch with ScaleSecure</div>
            </motion.button>
          </div>

          {/* Image */}
          <div className="relative h-auto flex justify-center md:w-full lg:w-1/2 xl:w-1/2">
            <motion.div
              className="w-72 md:w-full h-72 md:h-96"
              style={{ scale: imageScale }}
            >
              <img src={src} alt="image" className="object-cover rounded-3xl" />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
